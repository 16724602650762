tbmWorkerHealthStatus<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-table
            ref="attendeeTable"
            title="참석자 및 건강상태 목록"
            :columns="grid2.columns"
            :gridHeight="grid2.height"
            :data="data.tbmAttendeeModelList"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            noDataLabel="참석자가 없습니다."
            :hideBottom="true"
            :editable="editable&&!disabled"
            selection="multiple"
            rowKey="tbmAttendeeId"
            
          >
            <template slot="table-button">
              <q-btn-group outline>
                <!-- <c-btn
                  v-if="isWorkPermit"
                  :showLoading="false"
                  label="작업자 불러오기"
                  icon="check"
                  @btnClicked="workPermitLoadAttendee"
                /> -->
                <c-btn
                  v-if="editable && !disabled"
                  :showLoading="false"
                  label="일용직추가"
                  icon="add"
                  @btnClicked="addAttendee1"
                />
                <c-btn
                  v-if="editable && !disabled"
                  :showLoading="false"
                  label="사용자추가"
                  icon="add"
                  @btnClicked="addAttendee2"
                />
                <c-btn
                  v-if="
                    editable &&
                    !disabled &&
                    data.tbmAttendeeModelList.length > 0
                  "
                  :showLoading="false"
                  label="제외"
                  icon="remove"
                  @btnClicked="removeAttendee"
                />
              </q-btn-group>
            </template>
            <template v-slot:customArea="{ props, col }">
              <template v-if="col.name ==='tbmAttendeeUserName'">
                <c-text-column
                  v-if="props.row['tbmDirectFlag'] === 'Y'"
                  :editable="editable"
                  :props="props"
                  :col="col"
                  :disabled="disabled"
                  v-model="props.row['tbmAttendeeUserName']"
                  @datachange="datachange(props)"
                />
                <span v-else>
                  {{props.row['tbmAttendeeUserName']}}
                </span>
              </template>
              <template v-if="col.name ==='tbmAttendeeJobName'">
                <c-text-column
                  v-if="props.row['tbmDirectFlag'] === 'Y'"
                  :editable="editable"
                  :props="props"
                  :col="col"
                  :disabled="disabled"
                  v-model="props.row['tbmAttendeeJobName']"
                  @datachange="datachange(props)"
                />
                <span v-else>
                  {{props.row['tbmAttendeeJobName']}}
                </span>
              </template>
              <template v-if="col.name === 'click'">
                <q-btn 
                  round unelevated 
                  size="10px"
                  color="amber" 
                  icon="mode"
                  @click.stop="() => { rowIndex = props.rowIndex, electronSignature = props.row.electronSignature}"
                  >
                  <q-popup-proxy :ref="'proxy_' + props.rowIndex" :breakpoint="400">
                    <component
                      :is="col.component"
                      :outFlag="true"
                      :popupParam="props.row"
                      :data="data"
                      :rowIndex="props.rowIndex"
                      @callback="callback"
                    />
                  </q-popup-proxy>
                </q-btn>
              </template>
            </template>
          </c-table>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from "quasar";
import VueQrcode from '@chenfengyuan/vue-qrcode';
import selectConfig from "@/js/selectConfig";
import transactionConfig from "@/js/transactionConfig";
export default {
  name: "tbm-worker-health-status",
  components: {
    VueQrcode
  },
  props: {
    popupParam: {
      type: Object,
      default() {
        return {
          tbmId: "",
        };
      },
    },
    data: {
      type: Object,
      default() {
        return {
          tbmId: "",
          tbmNo: "",
          plantCd: "",
          permitNo: "",
          sopName: "",
          mapName: '',
          tbmWorkResponsibleId: "",
          tbmWorkResponsibleName: "",
          tbmName: "",
          tbmWorkDate: "",
          tbmWorkArea: "",
          tbmWorkContents: "",
          significant: "",
          tbmCompleteFlag: 'N',
          processCd: "",
          vendorCd: "",
          sopWorkPermitId: "",
          preInspections: "",
          tbmWorkPermitFlag: 'N',
          companyCd: '',

          tbmAttendeeModelList: [],
          tbmRiskHazardModelList: [],
        };
      },
    },
  },
  data() {
    return {
      grid2: {
        columns: [],
        height: '500px',
        data: [],
      },
      changeItem: {
        plant: '',
        process: '',
        vendor: '',
      },
      getUrl: "",
      saveUrl: "transactionConfig.sai.tbm.insert.url",
      completeUrl: "transactionConfig.sai.tbm.complete.url",
      deleteUrl: "",
      updateUrl: "",
      printUrl: "",
      getRespondUrl: '',
      getWorkPermitUrl: '',
      saveType: "POST",
      isSave: false,
      isComplete: false,
      isEdit: false,
      redirectUrl: '',
      serverName: '',
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        // isFull: true,
        top: "",
        param: {},
        closeCallback: null,
      },
      editable: false,
      scenarioViewGroup: 'L',
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  computed: {
    isWorkPermit() {
      return (
        Boolean(this.data.sopWorkPermitId) &&
        this.editable
      );
    },
    disabled() {
      return this.data.tbmCompleteFlag === "Y";
    },
    isPlant() {
      return Boolean(!this.data.plantCd)
    },
    isProcess() {
      return Boolean(!this.data.plantCd) || Boolean(!this.data.processCd)
    },
    isVendor() {
      if (this.$store.getters.user.innerFlag === 'N') {
        return true;
      } else {
        return false;
      }
    },
  },
  // * 작업허가서 있을때
  // - 프로젝트, 공종, 업체, 작업, 작업책임자(업체감독자), 작업장소, 작업일, 작업내용 전부 disabled
  // - 참석자 : 작업허가서의 작업자
  // * 작업허가서 없을때
  // - 공사현장과 관련된 공종 선택
  // - 작업장소 : 공사현장 > 작업장소
  // - 작업책임자 : 하도급 > 안전책임자
  // - 작업 : 공종, 업체에 맞는 작업 선택
  // - 업체 : 프로젝트와 공종에 맞는 하도급 선택
  // - 위험요인 목록  작업과 관련된 작업단계(직접추가 제외)
  // - 참석자 : 직영일때 사용자 추가(이름, 직책/직무 수정X), 업체일때 행추가(이름, 직책/직무 수정O) 
  methods: {
    init() {
      this.editable = this.$route.meta.editable;

      this.getRespondUrl = selectConfig.sai.tbm.respond.url;
      this.getWorkPermitUrl = selectConfig.sop.swp.workPermit.get.url;
      this.insertUrl = transactionConfig.sai.tbm.insert.url;
      this.updateUrl = transactionConfig.sai.tbm.update.url;
      this.completeUrl = transactionConfig.sai.tbm.complete.url;
      this.deleteUrl = transactionConfig.sai.tbm.delete.url;
      this.redirectUrl = selectConfig.sai.tbm.qrUrl.url;
      this.setHeader();
      this.serverUrl();
    },
    serverUrl() {
      this.$http.url = this.redirectUrl;
      this.$http.type = 'GET';
      this.$http.param = {};
      this.$http.request(
        _result => {
          this.serverName = _result.data
        },
        _error => {
          window.getApp.$emit('APP_REQUEST_ERROR', _error);
        }
      );
    },
    setHeader() {
      this.$comm.getComboItems('TBM_HEALTH_STATUS_CD').then(_item => {
        this.grid2.columns = [
          {
            name: "deptName",
            field: "deptName",
            label: "업체",
            align: "center",
            style: 'width:200px',
            sortable: false,
          },
          {
            name: "tbmAttendeeUserName",
            field: "tbmAttendeeUserName",
            label: "참석자",
            align: "center",
            style: 'width:130px',
            sortable: false,
          },
          // {
          //   name: "tbmAttendeeJobName",
          //   field: "tbmAttendeeJobName",
          //   label: "역할",
          //   style: 'width:100px',
          //   type: 'custom',
          //   align: "center",
          //   sortable: false,
          // },
          {
            name: "jobName",
            field: "jobName",
            label: "직위",
            style: 'width:130px',
            align: "center",
            sortable: false,
          },
          {
            name: 'healthStatusCd',
            field: 'healthStatusCd',
            label: '건강상태',
            type: 'select',
            setHeader: true,
            style: 'width:150px',
            align: 'center',
            comboItems: _item,
            sortable: false,
          },
          {
            name: 'mentalFatigueFlag',
            field: 'mentalFatigueFlag',
            label: '정신적<br>피로',
            style: 'width:70px', 
            align: 'center',
            type: 'check',
            true: 'Y',
            value: 'N',
            false: 'N',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'mentalPhychologicalFlag',
            field: 'mentalPhychologicalFlag',
            label: '심리적<br>피로',
            style: 'width:70px', 
            align: 'center',
            type: 'check',
            true: 'Y',
            value: 'N',
            false: 'N',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'mentalPhysicalFlag',
            field: 'mentalPhysicalFlag',
            label: '육체적<br>피로',
            style: 'width:70px', 
            align: 'center',
            type: 'check',
            value: 'N',
            true: 'Y',
            false: 'N',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'physicalDefectFlag',
            field: 'physicalDefectFlag',
            label: '신체적<br>결함',
            style: 'width:70px', 
            align: 'center',
            type: 'check',
            value: 'N',
            true: 'Y',
            false: 'N',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'eduAttendFlag',
            field: 'eduAttendFlag',
            label: '교육참석<br>여부',
            style: 'width:70px', 
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
          {
            name: 'click',
            field: 'click',
            label: '서명<br>',
            style: 'width:60px',
            type: 'custom',
            align: 'center',
            sortable: false,
            component: () => import(`${'./signaturePop.vue'}`)
          },
          // {
          //   label: '항목',
          //   align: 'center',
          //   sortable: false,
          //   child: [
          //   ]
          // },
          // {
          //   name: 'remark',
          //   field: 'remark',
          //   label: '비고',
          //   type: 'text',
          //   style: 'width:800px',
          //   align: 'left',
          //   sortable: false,
          // },
        ]
      });
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    addAttendee1() {
      this.popupOptions.title = "일용직 검색"; // TBM 참석자 검색
      this.popupOptions.param = {
        type: "multiple",
        isDayJobAll: true,
      };
      this.popupOptions.target = () => import(`${"@/pages/common/user/userPop.vue"}`);
      this.popupOptions.width = "50%";
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAttendeePopup1;
    },
    // addAttendee1() {
    //   this.popupOptions.title = "일용직 검색"; // TBM 참석자 검색
    //   this.popupOptions.param = {
    //     tbmId: this.popupParam.tbmId,
    //   };
    //   this.popupOptions.target = () => import(`${"./tbmDayJobAttendeePop.vue"}`);
    //   this.popupOptions.width = "60%";
    //   this.popupOptions.visible = true;
    //   this.popupOptions.closeCallback = this.closeAttendeePopup1;
    // },
    closeAttendeePopup1(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.data.tbmAttendeeModelList, {
            tbmAttendeeUserId: _item.userId,
          });
          if (index === -1) {
            this.data.tbmAttendeeModelList.push({
              tbmId: this.popupParam.tbmId,
              tbmAttendeeId: uid(),
              tbmAttendeeUserId: _item.userId,
              tbmAttendeeUserName: _item.userName,
              spotName: _item.spotName,
              jobName: '일용직',
              tbmAttendeeJobName: _item.projectRoleName ? _item.projectRoleName : _item.vendorUserRoleName,
              healthStatusCd: "THS0000001",
              deptName: _item.deptName,
              mentalFatigueFlag: "N",
              electronSignature: '',
              mentalPhychologicalFlag: "N",
              mentalPhysicalFlag: "N",
              eduAttendFlag: 'N',
              physicalDefectFlag: "N",
              tbmDirectFlag: "N",
              remark: "",
              regUserId: this.$store.getters.user.userId,
              editFlag: "C",
            })
          }
        })
      }
    },
    addAttendee2() {
      this.popupOptions.title = "사용자 검색"; // TBM 참석자 검색
      this.popupOptions.param = {
        type: "multiple",
        plantCd: this.data.plantCd,
        isAllVendor: true,
      };
      this.popupOptions.target = () => import(`${"@/pages/common/user/userPop.vue"}`);
      this.popupOptions.width = "50%";
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAttendeePopup2;
    },
    closeAttendeePopup2(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.data.tbmAttendeeModelList, {
            tbmAttendeeUserId: _item.userId,
          });
          if (index === -1) {
            this.data.tbmAttendeeModelList.push({
              tbmId: this.popupParam.tbmId,
              tbmAttendeeId: uid(),
              tbmAttendeeUserId: _item.userId,
              tbmAttendeeUserName: _item.userName,
              spotName: _item.spotName,
              jobName: _item.jobName,
              tbmAttendeeJobName: _item.projectRoleName ? _item.projectRoleName : _item.vendorUserRoleName,
              healthStatusCd: "THS0000001",
              deptName: _item.deptName,
              mentalFatigueFlag: "N",
              electronSignature: '',
              mentalPhychologicalFlag: "N",
              mentalPhysicalFlag: "N",
              eduAttendFlag: 'N',
              physicalDefectFlag: "N",
              tbmDirectFlag: "N",
              remark: "",
              regUserId: this.$store.getters.user.userId,
              editFlag: "C",
            })
          }
        })
      }
    },
    removeAttendee() {
      let selectData = this.$refs["attendeeTable"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "안내", // 안내
          message: "하나 이상 선택하세요.", // 하나 이상 선택하세요.
          type: "warning", // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          if (!this.data.deleteTbmAttendeeModelList) {
            this.data.deleteTbmAttendeeModelList = [];
          }
          if (
            this.$_.findIndex(this.data.deleteTbmAttendeeModelList, {
              tbmAttendeeId: item.tbmAttendeeId,
            }) === -1 &&
            item.editFlag !== "C"
          ) {
            this.data.deleteTbmAttendeeModelList.push(item);
          }
          this.data.tbmAttendeeModelList = this.$_.reject(
            this.data.tbmAttendeeModelList,
            item
          );
        });
      }
    },
    callback(data, rowIndex) {
      this.data.tbmAttendeeModelList[rowIndex].electronSignature = data
      this.data.tbmAttendeeModelList[rowIndex].eduAttendFlag = 'Y'
      if(this.data.tbmAttendeeModelList[rowIndex].editFlag !== 'C') {
        this.data.tbmAttendeeModelList[rowIndex].editFlag = 'U'
      }
      let refName = 'proxy_' + rowIndex;
      this.$refs[refName].hide();
    },
    // listView() {
    //   this.scenarioViewGroup = 'L'
    // },
    // cardView() {
    //   this.scenarioViewGroup = 'C'
    // },
  },
};
</script>
<style>
.canvas {
  width: 100%;
  height: 100%;
  margin: 3 auto;
  display: inline;
}
.wrap {
  display:flex;
  flex-wrap:wrap;
  padding:20px;
  height:400px;
  box-sizing:border-box;
  border-color: #aaa;
}
.facilityStyle {
  height:200px;
  width:300px;
  display:flex;
  align-items:center;
  justify-content:center;
  flex-wrap:wrap;
}
.abc {
  width:100%;
  height:20px;
  display:inline;
}
</style>